import $ from "jquery";
import "../lib/storage";

import "./Page";

// eslint-disable-next-line no-unused-expressions,no-console
window.console && window.console.info("Loading Catalog logic");
import("../page/Catalog");

// eslint-disable-next-line no-unused-expressions,no-console
window.console && window.console.info("Loading Cart logic");
import("../page/Cart");

// // crapppy hooks
if ($("#businessAccount").length === 1) {
  // eslint-disable-next-line no-unused-expressions,no-console
  window.console && window.console.info("Loading Profile logic");
  import("../page/index_profile");
}

// // crapppy hooks
if ($(".widget-trackandtrace").length === 1) {
  // eslint-disable-next-line no-unused-expressions,no-console
  window.console && window.console.info("Loading OrderStatus logic");
  import("../page/OrderStatus");
}

if ($(".order-status.complete").length === 1 || document.location.hash === "#logout") {
  // eslint-disable-next-line no-console,no-unused-expressions
  window.console.info && window.console.info("Clearing cart");
  if (window.cartStorage) {
    window.cartStorage.removeItem("cart-data");
  }
}

const updateCartInfo = () => {
  if (window.cartStorage) {
    try {
      const data = JSON.parse(window.cartStorage.getItem("cart-data"));
      if (data.items && data.items.length > 0) {
        $(".shoppingcart")
          .addClass("shoppingcart--active")
          .find(".shoppingcart__indicator__quantity")
          .html(
            data.items.reduce((carry, item) => {
              carry = carry + item.quantity + item.extraProducts.length;
              return carry;
            }, 0)
          );
      } else {
        $(".shoppingcart").removeClass("shoppingcart--active");
      }
    } catch (e) {
      /* ignore */
    }
  }
};

$(window).on("debloemist.cart.updated", updateCartInfo);
$(window).trigger("debloemist.cart.updated");
