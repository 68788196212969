import $ from "jquery";
import ucfirst from "locutus/php/strings/ucfirst";
import lcfirst from "locutus/php/strings/lcfirst";

const __DEBUG__ = false && __DEV__;

const getDataSetKey = key =>
  lcfirst(
    key
      .split("-")
      .map(v => ucfirst(v))
      .join("")
  );

const getElementData = (el, key) => {
  if (el.dataset) {
    return el.dataset[getDataSetKey(key)];
  }
  return el.getAttribute(`data-${key}`);
};

const setElementData = (el, key, value) => {
  if (el.dataset) {
    el.dataset[getDataSetKey(key)] = value;
  }
  return el.setAttribute(`data-${key}`, value);
};

const applyLazyLoad = el => {
  if (!el || getElementData(el, "is-lazy-loaded")) {
    return false;
  }
  if (__DEBUG__) {
    // eslint-disable-next-line no-console
    console.log("LazyLoading", el);
  }
  const datasetSrc = getElementData(el, "src");
  if (datasetSrc) {
    el.src = datasetSrc;
  }
  const datasetSrcSetSrcSet = getElementData(el, "srcset-srcset");
  if (datasetSrcSetSrcSet) {
    el.srcset = datasetSrcSetSrcSet;
  }
  const datasetSrcSetSizes = getElementData(el, "srcset-sizes");
  if (datasetSrcSetSizes) {
    el.sizes = datasetSrcSetSizes;
  }
  const datasetBackgroundImageUrl = getElementData(el, "background-image-url");
  if (datasetBackgroundImageUrl) {
    el.style.backgroundImage = `url(${datasetBackgroundImageUrl})`;
  }
  if (
    el &&
    ((el.classList && el.classList.contains("fb-page")) || (!el.classList && el.className.indexOf("fb-page") !== -1))
  ) {
    console.log("LazyLoading fb-page?", el);
    // inject facebook script
    const src =
      "https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v7.0&appId=287083128289850&autoLogAppEvents=1";
    const nonce = "8egMhmzk";
    if (!document.getElementById(src)) {
      const el = document.createElement("script");
      el.defer = el.async = true;
      el.crossorigin = "anonymous";
      el.src = el.id = src;
      el.nonce = nonce;
      document.getElementsByTagName("body")[0].appendChild(el);
    }
  }
  setElementData(el, "is-lazy-loaded", 1);
  return true;
};

const initLazyLoading = el => {
  if (!el || getElementData(el, "is-lazy-loaded")) {
    return false;
  }
  applyLazyLoad(el);
  return true;
};

// https://developers.google.com/web/updates/2016/04/intersectionobserver
const getIntersectionObserver = () => {
  if (window.IntersectionObserver) {
    $("html").addClass("intersection-observer");
    return new IntersectionObserver(
      entries => {
        if (__DEBUG__) {
          // eslint-disable-next-line no-console
          console.log("IntersectionObserver", entries);
        }
        if (entries.length) {
          entries.forEach(entry => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              initLazyLoading(entry.target);
            }
          });
        }
      },
      {
        rootMargin: "250px 250px 250px 250px"
      }
    );
  }
  return null;
};

const io = getIntersectionObserver();

const $lazyLoadFn = function e() {
  const el = this;
  if (io) {
    io.observe(el);
    return el;
  }
  // fallback
  setTimeout(() => {
    applyLazyLoad(el);
  }, 10);
  return el;
};

$.fn.lazyLoad = function lazyLoad() {
  return this.each($lazyLoadFn);
};
