// SessionStorage polyfill as found https://gist.github.com/remy/350433
// Mapped to window.cartStorage to overcome chrome private mode errors
function testStorage(storage) {
  storage.setItem("storage", "");
  if (storage.getItem("storage") !== "") {
    throw new Error("Storage test failed");
  }
  storage.removeItem("storage");
}

let cartStorage = undefined;

try {
  // Support for Mobile Safari in private mode --- it does not allow localStorage / sessionStorage
  testStorage(window.sessionStorage);
  cartStorage = window.sessionStorage;
  // eslint-disable-next-line no-empty
} catch (e) {}

if (typeof window !== "undefined" && typeof cartStorage === "undefined") {
  const Storage = function Storage() {
    function setData(data) {
      data = JSON.stringify(data);
      window.name = data;
    }

    function clearData() {
      window.name = "";
    }

    function getData() {
      let data;
      try {
        data = JSON.parse(window.name);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return data && typeof data === "object" ? data : {};
    }

    // initialise if there's already data
    let data = getData();

    return {
      length: 0,
      clear() {
        data = {};
        this.length = 0;
        clearData();
      },
      getItem(key) {
        return data[key] === undefined ? null : data[key];
      },
      key(i) {
        // not perfect, but works
        let ctr = 0;
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const k in data) {
          if (ctr === i) {
            return k;
          }
          ctr += 1;
        }
        return null;
      },
      removeItem(key) {
        delete data[key];
        this.length -= 1;
        setData(data);
      },
      setItem(key, value) {
        data[key] = `${value}`; // forces the value to a string
        this.length += 1;
        setData(data);
      }
    };
  };

  try {
    cartStorage = new Storage();
    testStorage(cartStorage);
  } catch (e) {
    if (typeof window !== "undefined") {
      if (typeof window.Raven !== "undefined") {
        window.Raven.setTagsContext({ cart: "storage" });
        window.Raven.captureException(e);
      }
      // eslint-disable-next-line no-alert
      window.alert(
        document.getElementsByTagName("html")[0].getAttribute("lang") === "nl"
          ? // eslint-disable-next-line max-len
            "Uw browser kan vereiste informatie van www.debloemist.nl niet opslaan.\n" +
              "\nSchakel privé-modus uit voor een juiste werking van de website."
          : // eslint-disable-next-line max-len
            "Your browser can not store the required information from www.debloemist.nl.\n" +
              "\nDisable incognito-mode for a correct working."
      );
    }
  }
}

if (typeof window !== "undefined") {
  window.cartStorage = cartStorage;
}

export default cartStorage;
