import $ from "jquery";

import "./polyfill";

const $html = $("html")
  .addClass("js")
  .removeClass("no-js");

// Does not seem to have the desired effect...
// if (window.navigator.userAgent.match(/Trident\/7\./)) { // if IE-11
//   $('body').addClass('disabled-smooth-scrolling').on('mousewheel  DOMMouseScroll', (e) => {
//     // remove default behavior
//     e.preventDefault();
//
//     // scroll without smoothing
//     window.scrollTo(0, window.pageYOffset - e.originalEvent.wheelDelta);
//   });
// }

let hasImgWebPSupport = false;

function canUseWebP() {
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    elem.height = 1;
    elem.width = 1;
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
}

if (!$html.hasClass("img-webp") && canUseWebP()) {
  hasImgWebPSupport = true;
  $html.addClass("img-webp");
}

if (window.matchMedia("(pointer: none").matches) {
  $html.addClass("pointer-none");
}

if (window.matchMedia("(pointer: fine").matches) {
  $html.addClass("pointer-fine");
}

if (window.matchMedia("(pointer: course").matches) {
  $html.addClass("pointer-course");
}

if (window.matchMedia("(hover)").matches) {
  $html.addClass("has-hover");
}

if ("ontouchstart" in document.documentElement) {
  $html.addClass("has-touch-events");
}

function canSupportPassiveEvents() {
  // Test via a getter in the options object to see if the passive property is accessed
  let supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, "passive", {
      // eslint-disable-next-line object-shorthand,getter-return
      get: function get() {
        supportsPassive = true;
      }
    });
    window.addEventListener("testPassive", null, opts);
    window.removeEventListener("testPassive", null, opts);
  } catch (e) {
    // ignore
  }
  return supportsPassive;
}

let passiveEvents = false;
if (!$html.hasClass("passive-events") && canSupportPassiveEvents()) {
  passiveEvents = true;
  $html.addClass("passive-events");
}

export default {
  webp: hasImgWebPSupport,
  passiveEvents
};
