// fix for downstream libraries in ie8/ie9 etc
if (!window.console) {
  const noop = () => {};
  window.console = {
    log: noop,
    info: noop,
    warn: noop,
    error: noop,
    trace: noop
  };
}
if (!window.console.info) {
  window.console.info = window.console.log;
}
