import $ from "jquery";
import uuid from "uuid";
import Immutable from "immutable";
import merge from "lodash/merge";
import qs from "locutus/php/url/http_build_query";

import moment from "./moment";
import "./storage";

export const EMPTY_LIST = Immutable.List();
export const EMPTY_MAP = Immutable.Map();

const INTERFACE_VERSION = 1.043; // change this to remove local/session storage meuk

export const getData = (initialData, identifier) => {
  let dataFromEl = {};
  const fromEl = document.getElementById(identifier);
  if (fromEl) {
    const data = fromEl.innerText;
    if (data) {
      try {
        dataFromEl = JSON.parse(data);
        if (window.cartStorage) {
          window.cartStorage.setItem(`${identifier}-fromEl`, data);
        }
      } catch (err) {
        /* ignore */
      }
    }
  } else {
    const fromStorage = window.cartStorage && window.cartStorage.getItem(`${identifier}-fromEl`);
    if (fromStorage) {
      try {
        dataFromEl = JSON.parse(fromStorage);
      } catch (err) {
        /* ignore */
      }
    }
  }

  let dataFromStorage = {};
  const fromStorage = window.cartStorage && window.cartStorage.getItem(identifier);
  if (fromStorage) {
    try {
      dataFromStorage = JSON.parse(fromStorage);
      if (typeof dataFromStorage.version === "undefined" || dataFromStorage.version < INTERFACE_VERSION) {
        window.cartStorage.removeItem(identifier);
        dataFromStorage = {};
      }
    } catch (err) {
      /* ignore */
    }
  }

  return Immutable.fromJS(initialData)
    .set("created", moment().format())
    .set("version", INTERFACE_VERSION)
    .mergeDeep(dataFromEl)
    .mergeDeep(dataFromStorage)
    .set("webProductId", dataFromEl.webProductId);
};

export const getCartData = initialData =>
  getData(initialData, "cart-data").deleteIn(["checkout", "termsAndConditions"]);

export const getCartContextData = initialData =>
  getData(initialData, "cart-context")
    .setIn(["webProduct", "uuid"], uuid())
    .setIn(["currentItemContext", "uuid"], uuid());

export const getImageUrl = props => {
  if (props.imageVersionId) {
    props.ttl = 644400;
  }
  props.fileType = "auto";
  return `/download/?${qs(props)}`;
};

let $html = null;
let $body = null;

function getBody() {
  if ($body === null) {
    $body = $("body");
  }
  return $body;
}

export const isOldIE = () => {
  if ($html === null) {
    $html = $("html");
  }
  return $html.hasClass("oldie");
};

export const isFrontPage = () => getBody().hasClass("is-root");

export const isLoggedIn = () => getBody().hasClass("is-loggedin");

export const isNonProduction = () => getBody().hasClass("is-npe");

export const isMobileVisit = () => $(".mobile-menu").is(":visible");

export const isCart = () => document.getElementById("cart") !== null;

export const isCartPaid = () => $(".order-status.complete").length === 1;

function toFixedFix(n, pr) {
  const k = Math.pow(10, pr);
  return `${(Math.round(n * k) / k).toFixed(pr)}`;
}

export const price = (number, opts) => {
  opts = merge(
    {},
    {
      decimals: 2,
      dec_point: ",",
      thousands_sep: ".",
      includeCurrency: "€ ",
      dashCents: true
    },
    opts === false
      ? {
          includeCurrency: false
        }
      : opts || {}
  );

  number = `${number}`.replace(/[^0-9+\-Ee.]/g, "");
  // eslint-disable-next-line no-restricted-globals
  const num = !isFinite(+number) ? 0 : +number;
  // eslint-disable-next-line no-restricted-globals
  const prec = !isFinite(+opts.decimals) ? 0 : Math.abs(opts.decimals);
  const sep = typeof opts.thousands_sep === "undefined" ? "," : opts.thousands_sep;
  const dec = typeof opts.dec_point === "undefined" ? "." : opts.dec_point;

  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  const s = (prec ? toFixedFix(num, prec) : `${Math.round(num)}`).split(".");

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }

  return (opts.includeCurrency || "") + s.join(dec).replace(",00", opts.dashCents ? ",-" : ",00");
};

export const priceLegacy = (number, includeCurrency) =>
  price(price, {
    includeCurrency
  });

// Fix for IE parseFloat(0.55).toFixed(0) = 0;
export const priceAsFloat = number => parseFloat((Math.round(number * 100) / 100).toFixed(2));

export const hasClass = selector => {
  if ($html === null) {
    $html = $("html");
  }
  return $html.hasClass(selector);
};

export const hasBodyClass = selector => getBody().hasClass(selector);

export const letsGetStickyWithIt = $els => {
  if ($els.nodeName) {
    $els = $($els);
  }
  // if (!hasClass('ie-lte-9') || $els.length === 0) {
  //   return;
  // }
  // console.log('letsGetStickyWithIt', $els.length);
  // import('jquery-sticky').then(() => {
  //   // {topSpacing:0}
  //   $els.each(function each() {
  //     const $el = $(this);
  //     console.log(JSON.stringify({
  //       topSpacing: $el.css('top'),
  //       bottomSpacing: $el.parent().offset().top
  //     }));
  //     $el.sticky({
  //       topSpacing: $el.css('top'),
  //       bottomSpacing: $el.parent().offset().top
  //     });
  //   });
  // });
};

export default {
  hasClass,
  hasBodyClass,
  letsGetStickyWithIt,

  /**
   *
   * @param number
   *
   * @returns {Number}
   */
  priceAsFloat,

  /**
   *
   * @param number
   * @param includeCurrency
   *
   * @returns {string}
   */
  price,

  /**
   * @param number
   * @param decimals
   * @param decPoint
   * @param thousandsSep
   *
   * @return {String}
   */
  numberFormat(number, decimals, decPoint, thousandsSep) {
    number = String(number).replace(/[^0-9+\-Ee.]/g, "");

    // eslint-disable-next-line no-restricted-globals
    const num = !isFinite(+number) ? 0 : +number;
    // eslint-disable-next-line no-restricted-globals
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
    const dec = typeof decPoint === "undefined" ? "." : decPoint;

    function toFixedFix(n, pr) {
      // eslint-disable-next-line no-restricted-properties
      const k = Math.pow(10, pr);
      return String(Math.round(n * k) / k);
    }

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    const s = (prec ? toFixedFix(num, prec) : String(Math.round(num))).split(".");

    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    let i;
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      i = prec - s[1].length;
      while (i) {
        s[1] += "0";
        i -= 1;
      }
    }
    return s.join(dec);
  },

  flatten(target, opts) {
    opts = opts || {};

    const delimiter = opts.delimiter || ".";
    const output = {};

    function step(object, prev) {
      // eslint-disable-next-line consistent-return
      Object.keys(object).forEach(key => {
        const value = object[key];
        const isarray = opts.safe && Array.isArray(value);
        const type = Object.prototype.toString.call(value);
        const isobject = type === "[object Object]" || type === "[object Array]";

        const newKey = prev ? prev + delimiter + key : key;

        if (!isarray && isobject) {
          return step(value, newKey);
        }

        output[newKey] = value;
      });
    }

    step(target);
    return output;
  }
};
