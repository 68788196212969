import $ from "jquery";

function doClick(/* e */) {
  const province = [];
  /* jshint -W040 */
  $(this)
    .find("tspan")
    .each((idx, el) => {
      /* jshint +W040 */
      province.push($(el).text());
    });
  window.location = `/nederland/bloemen-bestellen/${province.join("-").replace(/\s/, "-")}`;
}

const $el = $("#location-map-container");
if ($el.length > 0) {
  const $regions = $el.find("g path");
  const $labels = $el.find("g text");

  $regions.each((idx, el) => {
    const $region = $(el);
    const $label = $($labels.get(idx));

    $region
      .on("mouseover", () => {
        $region.attr("class", "active");
        $label.attr("class", "active");
      })
      .on("mouseout", () => {
        $region.attr("class", "");
        $label.attr("class", "");
      })
      .on("click", doClick.bind($label))
      .on("touchstart", doClick.bind($label));

    $label
      .on("mouseover", () => {
        $region.attr("class", "active");
        $label.attr("class", "active");
      })
      .on("mouseout", () => {
        $region.attr("class", "");
        $label.attr("class", "");
      })
      .on("click", doClick.bind($label))
      .on("touchstart", doClick.bind($label));
  });
}
