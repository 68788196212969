import "default-passive-events";
import $ from "jquery";

import "./lib/browserUpdate";
import "./lib/lazyLoad";
import "./lib/progressive";
import registerServiceWorker from "./lib/registerServiceWorker";
import { hasClass, letsGetStickyWithIt } from "./lib/utils";

import "./style/index.scss";

$.ajaxSetup({ cache: false });

$(document).on("ready", () => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(process.env);
  }

  // lazy load images
  $(".sprite")
    .attr("data-background-image-url", "/img/sprite.kmta1b2c3.png")
    .lazyLoad();
  $("img[data-src],[data-background-image-url],.fb-page").lazyLoad();

  // eslint-disable-next-line global-require
  require("./page");

  // fix external links to target a new tab/window
  $('a[rel="external"]')
    .attr("target", "_blank")
    .attr("rel", "noopener");

  // is-catalog is-root is-npe header--fixed--banner header--minimal header--fixed is-mobile-menu is-mobile
  // is-catalog is-root is-npe header--fixed--banner header--minimal header--fixed is-mobile-menu

  // Get the header
  const $html = $("html");
  const $body = $("body");
  const $header = $("#header");
  const $headerTop = $(".header-top");
  const $banner = $(".banner");
  const $menuContainer = $(".header__menu");
  const $menu = $(".main-menu.category-menu");
  const hasBanner = $banner.length !== 0;
  const $categoryFilters = $(".category__filters");
  const $categoryList = $(".categorylist");

  if (!$body.hasClass("is-mobile") && window.matchMedia) {
    const mediaQueryList = window.matchMedia("(min-width: 1080px)");

    const listener = mql => {
      const isMobile = !mql.matches;
      // header--fixed
      // header--minimal
      $body.toggleClass("is-mobile", isMobile);
      if (!($body.hasClass("is-cart") || $body.hasClass("is-payment"))) {
        $body.toggleClass("header--fixed", isMobile);
        $body.toggleClass("header--minimal", isMobile);
      }

      if (!isMobile && $body.hasClass("is-touch-device")) {
        $menuContainer
          .find("#menu > li > a")
          .off("click")
          .css("touch-action", "none")
          .on("click", e => {
            if (
              $(e.target)
                .parent()
                .hasClass("home")
            ) {
              return true;
            }
            // is-touch-device
            e.preventDefault();
            return false;
          });
      }
    };

    mediaQueryList.addListener(listener);
    listener(mediaQueryList);
  }

  let isWithoutStickyFilters = false;
  if (window.matchMedia) {
    const mediaQueryList = window.matchMedia("(min-height: 640px)");

    const listener = mql => {
      const newVal = !mql.matches;
      if (newVal === isWithoutStickyFilters) {
        return;
      }
      isWithoutStickyFilters = newVal;
      $(window).trigger("scroll");
    };

    mediaQueryList.addListener(listener);
    listener(mediaQueryList);
  }

  const catListTop = 78;
  const catFiltersTop = 66;

  // Get the offset position of the navbar
  function affixHeader() {
    const htmlMarginTop = parseInt($html.css("marginTop").replace("px", "") || 0, 10);

    const sticky = ($header.length > 0 ? $header.offset().top : 176) + htmlMarginTop;

    const yOffset =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop;

    if ($body.hasClass("is-mobile")) {
      return;
    }

    if ($body.hasClass("is-cart") || $body.hasClass("is-payment") || $body.hasClass("is-mobile")) {
      $menu.find("ul > li > ul").css({
        left: $headerTop.offset().left,
        minWidth: $headerTop.offset().left + $headerTop.width
      });
      if (htmlMarginTop) {
        $header.css("top", htmlMarginTop);
      }
      return;
    }

    const bannerHeight = $banner.height() + htmlMarginTop;

    if (yOffset <= bannerHeight) {
      $body.removeClass("header--minimal");
      $categoryList.css("top", catListTop);
      $menu.find("ul > li > ul").css("left", 0);
      $headerTop.after($menuContainer.detach());
      if ($categoryFilters.length) {
        $categoryFilters
          .css("top", catFiltersTop)
          .removeClass("--fixed")
          .css("left", "inherit")
          .css("width", "inherit");
        $(".category__contents").css("padding-top", "inherit");
      }
    }

    if (yOffset <= sticky) {
      $body.removeClass("header--fixed");
    }

    if (yOffset >= 1) {
      $body.addClass("header--fixed");
      if (hasBanner) {
        $body.addClass("header--fixed--banner");
      }
      if (htmlMarginTop) {
        $header.css("top", htmlMarginTop);
      }

      const headerHeight = $header.height() + htmlMarginTop;
      if ((!hasBanner && yOffset >= headerHeight) || (hasBanner && yOffset >= bannerHeight)) {
        $body.addClass("header--minimal");

        $categoryList.css("top", catListTop + htmlMarginTop);
        $(".logo-container").after($menuContainer.detach());
        if ($categoryFilters.length) {
          const $categoryContents = $(".category__contents");
          $categoryFilters
            .addClass("--fixed")
            .css("left", isWithoutStickyFilters ? 0 : $categoryContents.offset().left);

          $categoryFilters.css("top", catFiltersTop + htmlMarginTop).css("width", $categoryContents.width());
          $categoryContents.css("padding-top", $categoryFilters.height() + 20 + htmlMarginTop);
        }
        $menu.find("ul > li > ul").css({
          left: $headerTop.offset().left,
          minWidth: $headerTop.offset().left + $headerTop.width
        });
      }
    }
  }

  window.addEventListener("scroll", affixHeader);
  $(window).trigger("scroll");

  setTimeout(() => {
    affixHeader(); // for initial
  }, 10);
  setTimeout(() => {
    affixHeader(); // for initial
  }, 150);
  if (__DEV__) {
    setTimeout(() => {
      affixHeader(); // for initial
    }, 300);
  }

  $("input, select, textarea").attr("autocomplete", "off"); // probably does not work here; though is same as before

  if (hasClass("ie-lte-9")) {
    letsGetStickyWithIt($(".categorylist, .boxes > .box, .StickyContainer"));
  }
});

if (module.hot) {
  module.hot.accept();
}

registerServiceWorker();
