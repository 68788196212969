import $ from "jquery";
import "slick-carousel";
import "./map";

if (window.coverModal) {
  import("./coverModal");
}
import("./Menu"); // chunk the menu code

// Footer
$(".footer").on("click", ".menu > li", e => {
  const $target = $(e.target);
  if (
    ($("body").hasClass("is-mobile") || $target.css("backgound-color") === "#efefef") &&
    $target
      .parent()
      .parent()
      .hasClass("menu")
  ) {
    $(e.target)
      .parent()
      .toggleClass("--expanded");
    return false;
  }
  if ($(e.target).attr("href") === "#") {
    return false;
  }
  return true;
});

$(".siteusps").slick({
  fade: true,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 200
});

$(".cycle").slick({
  fade: true,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 3000
});

$("li.blog-archive-tree-year > a").on("click", function c() {
  $(this)
    .parent()
    .find("ul")
    .toggle();
  return false;
});
